import React, { useEffect } from 'react';
import './App.css';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import AppContext from './AppContext';
import MainApp from './MainApp';


function App() {


  useEffect(() => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [])

  return (
    <AppContext.Provider value={null}>
      <div className="text-center items-center justify-center h-full bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-50">
        <MainApp />
      </div>
    </AppContext.Provider>
  );
}

export default App;
