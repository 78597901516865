import React, { useState,useEffect } from 'react';
import DarkModeToggle from 'react-dark-mode-toggle';
import PropTypes from 'prop-types';
import AppContext from '../AppContext';

function ThemeToggler(props) {


  const { onClick } = props;
  const handleOnChange = (darkMode) => {
    if(darkMode){

      localStorage.theme = 'light';
      document.documentElement.classList.remove('dark')
    }else{
      localStorage.theme = 'dark'
      document.documentElement.classList.add('dark')
    }
    setDarkMode(!darkMode)
    onClick();
  };
  const [darkMode,setDarkMode] = useState(true)
  useEffect(()=>{
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      setDarkMode(true)
    } else {
      setDarkMode(false)
    }
  },[])

  return (
    <>


          <div style={{ marginBottom: 8 }}>
            <DarkModeToggle
              onChange={() => handleOnChange(darkMode)}
              checked={darkMode}
              size={50}
            />
          </div>

    </>
  );
}

// ThemeToggler.propTypes = {
//   onClick: PropTypes.func,
// };
// ThemeToggler.defaultProps = {
//   onClick: () => {},
// };

export default ThemeToggler;
