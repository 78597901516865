import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FallbackSpinner from './components/FallbackSpinner';
import NavBar from './components/NavBar';
import Home from './components/Home';
import endpoints from './constants/endpoints';
const routes = require("./json-data/routes.json")

function MainApp() {
  const [data, setData] = useState(routes);



  return (
    <div className="w-full">
      <NavBar/>
      <main className=" h-auto mt-8vh">
        <BrowserRouter>
        <Suspense fallback={<FallbackSpinner />}>
            <Routes>
              <Route exact path="/" element={<Home/>} />
              {data
                && data.sections.map((route) => {
                  const SectionComponent = React.lazy(() => import('./components/' + route.component));
                  return (
                    <Route
                      exact
                      key={route.headerTitle}
                      path={route.path}
                      element={<SectionComponent header={route.headerTitle} />}
                    />
                  );
                })}
            </Routes>
            </Suspense>
        </BrowserRouter>
      </main>
    </div>
  );
}

export default MainApp;
