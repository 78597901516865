import React, { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import { Fade } from "react-awesome-reveal";
import endpoints from '../constants/endpoints';
import Social from './Social';
import FallbackSpinner from './FallbackSpinner';
const home = require("../json-data/home.json");

function Home() {
  const [data, setData] = useState(home);

  return data ? (

    <div className='min-h-92vh h-92vh flex flex-col justify-center items-center align-middle'>
      <Fade>
        <h1 className='text-6xl'>{data?.name}</h1>
        <div className='flex-row text-2xl py-4 '>
          <h2 className="inline-block" >I&apos;m&nbsp;</h2>
          <Typewriter
            options={{
              loop: true,
              autoStart: true,
              strings: data?.roles,
            }}
          />
        </div>
        <Social />
      </Fade>
    </div>
  ) : <FallbackSpinner />;
}

export default Home;
